import { createSlice } from "@reduxjs/toolkit";
import ListenSheet from "../../services/ListenSheet";

export const listenTabSlice = createSlice({
  name: "listenTab",
  initialState: {
    currentPlaylist: null,
    currentSong: null,
    playlistOffset: 0,
    playlists: null,
  },
  reducers: {
    setPlaylist: (state, action) => {
      state.currentPlaylist = action.payload;
    },
    setCurrentSong: (state, action) => {
      state.currentSong = action.payload;
    },
    setPlaylistOffset: (state, action) => {
      state.playlistOffset = action.payload;
    },
    setPlaylists: (state, action) => {
      state.playlists = action.payload;
    },
  },
});

export const {
  setPlaylists,
  setPlaylist,
  setCurrentSong,
  setPlaylistOffset,
} = listenTabSlice.actions;

export const getPlaylists = () => (dispatch) => {
  ListenSheet.getListenPlaylists().then((playlists) => {
    dispatch(setPlaylists(playlists));
    dispatch(setPlaylist(playlists[0]));
  });
};

export const selectCurrentPlaylist = (state) => state.listenTab.currentPlaylist;
export const selectCurrentSong = (state) => state.listenTab.currentSong;
export const selectPlaylistOffset = (state) => state.listenTab.playlistOffset;
export const selectPlaylists = (state) => state.listenTab.playlists;

export default listenTabSlice.reducer;
