import { createSlice } from "@reduxjs/toolkit";
import ListenSheet from "../../services/ListenSheet";

export const homepageSlice = createSlice({
  name: "homepage",
  initialState: {
    playlists: null,
    todaysSong: null,
    songs: null,
    whatsNew: null,
  },
  reducers: {
    setPlaylists: (state, action) => {
      state.playlists = action.payload;
    },
    setTodaysSong: (state, action) => {
      state.todaysSong = action.payload;
    },
    setSongs: (state, action) => {
      state.songs = action.payload;
    },
    setWhatsNew: (state, action) => {
      state.whatsNew = action.payload;
    },
  },
});

export const {
  setPlaylists,
  setTodaysSong,
  setSongs,
  setWhatsNew,
} = homepageSlice.actions;

export const getPlaylists = () => (dispatch) => {
  ListenSheet.getPlaylistsFromSheets().then((playlists) => {
    dispatch(setPlaylists(playlists));
  });
};

export const getTodaysSong = () => (dispatch) => {
  ListenSheet.getTodaysSong().then((song) => {
    dispatch(setTodaysSong(song));
  });
};

export const getSongs = () => (dispatch) => {
  ListenSheet.getAllSongs().then((songs) => {
    dispatch(setSongs(songs));
  });
};

export const getWhatsNew = () => (dispatch) => {
  ListenSheet.getWhatsNew().then((whatsNew) => {
    dispatch(setWhatsNew(whatsNew));
  });
};

export const selectPlaylists = (state) => state.homepage.playlists;
export const selectTodaysSong = (state) => state.homepage.todaysSong;
export const selectSongs = (state) => state.homepage.songs;
export const selectWhatsNew = (state) => state.homepage.whatsNew;

export default homepageSlice.reducer;
