import React from "react";
import { Dimmer, Loader, Segment } from "semantic-ui-react";

const LoaderSegment = ({ style }) => (
  <Segment style={style}>
    <Dimmer active>
      <Loader />
    </Dimmer>
  </Segment>
);

export default LoaderSegment;
