import React from "react";
import { Header, Image, Reveal, Icon, Button, List } from "semantic-ui-react";

const SongImage = ({
  handleUnauthorizedClick,
  handleClick,
  song,
  size,
  src,
  isAuthorized,
}) => {
  return (
    <Reveal animated="move up">
      <Reveal.Content visible>
        <Image src={src} />
      </Reveal.Content>
      <Reveal.Content hidden>
        <div style={{ textAlign: "center", paddingTop: "25px" }}>
          <Header as="h2" inverted>
            {song.artist}
          </Header>
          <Header as="h2" inverted>
            {song.song}
          </Header>
          {isAuthorized ? (
            <Button onClick={(e) => handleClick(e, song)} inverted icon>
              <Icon name="play"></Icon>
            </Button>
          ) : (
            <div>
              <Button
                onClick={(e) => handleUnauthorizedClick(e, song)}
                inverted
              >
                more
              </Button>
              <br></br>
              <List horizontal relaxed style={{ paddingTop: "20px" }}>
                <List.Item as="a">
                  <Icon size="large" name="spotify"></Icon>
                </List.Item>
                <List.Item as="a">
                  <Icon size="large" name="apple"></Icon>
                </List.Item>
              </List>
            </div>
          )}
        </div>
      </Reveal.Content>
    </Reveal>
  );
};

export default SongImage;
