import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import SpotifyPlayer from "react-spotify-web-playback";
import withToken from "./withToken";
import {
  setCurrentSong,
  selectCurrentSong,
  selectPlaylistOffset,
} from "../spotifyAuth/listenTabSlice";
import { selectUriList } from "../spotifyAuth/playerSlice";
import { Menu } from "semantic-ui-react";

const Player = ({ spotify, token }) => {
  const dispatch = useDispatch();
  const currentSong = useSelector(selectCurrentSong);
  const playlistOffset = useSelector(selectPlaylistOffset);
  const uriList = useSelector(selectUriList);
  const [play, setPlay] = useState(true);

  useEffect(() => {
    setPlay(true);
  }, [uriList, playlistOffset]);

  const handleCallback = useCallback(
    ({ type, ...state }: CallbackState) => {
      let song = state.track;
      setPlay(state.isPlaying);
      if (
        song.id.length !== 0 &&
        (!currentSong || song.id !== currentSong.id)
      ) {
        dispatch(setCurrentSong(song));
      }
    },
    [currentSong, dispatch]
  );
  return (
    <Menu fixed="bottom">
      {token && uriList && (
        <SpotifyPlayer
          persistDeviceSelection
          syncExternalDevice
          callback={handleCallback}
          token={token}
          uris={uriList}
          offset={playlistOffset}
          name={"Shit You Should Be"}
          autoPlay={false}
          play={play}
          styles={{
            activeColor: "#fff",
            bgColor: "#fff",
            color: "#000",
            loaderColor: "#000",
            sliderColor: "#FD20CB",
            trackArtistColor: "#000",
            trackNameColor: "#000",
          }}
        />
      )}
    </Menu>
  );
};

export default withToken(Player);
