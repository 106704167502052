import React from "react";
import { Menu } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import {
  setPlaylistOffset,
  setPlaylist,
  selectCurrentPlaylist,
} from "../spotifyAuth/listenTabSlice";

const TrackListItem = ({ playlist, track, isActive, offsetIndex, offset }) => {
  const name = track.track.name;
  const artist = track.track.artists.map(({ name }) => name).join(", ");
  const dispatch = useDispatch();
  const currentPlaylist = useSelector(selectCurrentPlaylist);

  const handleItemClick = () => {
    if (playlist !== currentPlaylist) {
      dispatch(setPlaylist(playlist));
    }
    dispatch(setPlaylistOffset(offsetIndex + offset));
  };

  return (
    <Menu.Item
      style={{ color: "black" }}
      active={isActive}
      onClick={handleItemClick}
    >
      {name} - {artist}
    </Menu.Item>
  );
};

export default TrackListItem;
