import React, { useState, useEffect } from "react";
import { Grid } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { selectWhatsNew } from "../../features/spotifyAuth/homepageSlice";
import LoaderSegment from "./LoaderSegment";

const WhatsNew = () => {
  const whatsNew = useSelector(selectWhatsNew);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let timerFunc = setTimeout(() => {
      setLoading(false);
    }, 500);
    return () => clearTimeout(timerFunc);
  });

  if (loading)
    return (
      <LoaderSegment
        style={{ height: "315px", width: "100%", backgroundColor: "black" }}
      />
    );

  if (whatsNew) {
    return (
      <Grid.Column>
        <div style={{ margin: "0 10%" }}>
          <iframe
            title="whats-new-video"
            width="100%"
            height="315"
            src="https://www.youtube.com/embed/r17Mhy2q2dU"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </Grid.Column>
    );
  }
};

export default WhatsNew;
