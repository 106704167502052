import React from "react";
import { Menu, Icon, Image, Dropdown } from "semantic-ui-react";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import styled from "styled-components";
import logo from "../../sysb2OPTOMISED.gif";
import {
  selectProfile,
  selectIsAuthorized,
  logout,
  login,
} from "../../features/spotifyAuth/spotifyAuthSlice";
import { useDispatch, useSelector } from "react-redux";
import { faTiktok } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const StyledMenu = styled(Menu)`
  background-color: black !important;
  height: 75px;
  font-family: "Mitr-SemiBold";
`;

const GlobalHeader = () => {
  const isAuthorized = useSelector(selectIsAuthorized);
  const profile = useSelector(selectProfile);
  const dispatch = useDispatch();

  const isSmallScreen = useMediaQuery({ query: "(max-width: 1099px)" });

  const isLargeScreen = useMediaQuery({ query: "(min-width: 1100px)" });

  return (
    <div>
      <StyledMenu fixed="top">
        <Menu.Item>
          <Link to="/home">
            <Image id="header-image" size="small" src={logo}></Image>
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link className="menu-link" to="/discover">
            DISCOVER
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link className="menu-link" to="/watch">
            WATCH
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link className="menu-link" to="/listen">
            LISTEN
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link className="menu-link" to="/shop">
            SHOP
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link className="menu-link" to="/contact">
            CONTACT
          </Link>
        </Menu.Item>
        <Menu.Menu icon="true" position="right">
          {isLargeScreen && (
            <>
              <Menu.Item name="instagram">
                <a
                  href="https://www.instagram.com/shityoushouldbelisteningto/?hl=en"
                  target="blank"
                >
                  <Icon name="instagram" className="menu-icon" />
                </a>
              </Menu.Item>
              <Menu.Item name="spotify">
                <a
                  href="https://open.spotify.com/user/mj4qe0lyr7yqzm0zwqn5lc7yt?si=CB3hGTztTaSzionpVDbpCw"
                  target="blank"
                >
                  <Icon name="spotify" className="menu-icon" />
                </a>
              </Menu.Item>
              <Menu.Item name="apple">
                <a
                  href="https://music.apple.com/profile/shityoushouldbelisteningto"
                  target="blank"
                >
                  <Icon name="apple" className="menu-icon" />
                </a>
              </Menu.Item>
              <Menu.Item name="youtube">
                <a
                  href="https://www.youtube.com/channel/UCOgDIiVa8JdSX5T2Zswz9Bw/videos"
                  target="blank"
                >
                  <Icon name="youtube" className="menu-icon" />
                </a>
              </Menu.Item>
              <Menu.Item name="twitter">
                <a href="https://twitter.com/shityoushouldbe" target="blank">
                  <Icon name="twitter" className="menu-icon" />
                </a>
              </Menu.Item>
              <Menu.Item name="tiktok">
                <a href="https://www.tiktok.com/@sysblt?lang=en" target="blank">
                  <FontAwesomeIcon className="menu-icon" icon={faTiktok} />
                </a>
              </Menu.Item>
              {!isAuthorized && (
                <Menu.Item name="user">
                  <Dropdown icon="user">
                    <Dropdown.Menu className="user-icon-dropdown">
                      <Dropdown.Item
                        onClick={() => dispatch(login())}
                        id="login-menu-item"
                      >
                        login
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Menu.Item>
              )}
              {isAuthorized && profile && (
                <Menu.Item name="user">
                  <Dropdown icon="user" className="active-icon">
                    <Dropdown.Menu className="user-icon-dropdown">
                      <Dropdown.Item id="login-menu-item">
                        <a
                          href={profile.external_urls.spotify}
                          target="blank"
                          style={{ color: "#fd20cb" }}
                        >
                          {profile.display_name}
                        </a>
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => dispatch(logout())}
                        id="login-menu-item"
                      >
                        logout
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Menu.Item>
              )}
            </>
          )}

          {isSmallScreen && (
            <>
              <Menu.Item>
                <Dropdown
                  icon="bars"
                  floating
                  button
                  className="bars-menu-dropdown"
                >
                  <Dropdown.Menu className="bars-menu-menu">
                    <Dropdown.Item className="bars-menu-item">
                      <a
                        href="https://www.instagram.com/shityoushouldbelisteningto/?hl=en"
                        target="blank"
                      >
                        <Icon
                          name="instagram"
                          className="menu-icon-compressed"
                        />
                      </a>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <a
                        href="https://open.spotify.com/user/mj4qe0lyr7yqzm0zwqn5lc7yt?si=CB3hGTztTaSzionpVDbpCw"
                        target="blank"
                      >
                        <Icon name="spotify" className="menu-icon-compressed" />
                      </a>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <a
                        href="https://music.apple.com/profile/shityoushouldbelisteningto"
                        target="blank"
                      >
                        <Icon name="apple" className="menu-icon-compressed" />
                      </a>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <a
                        href="https://www.youtube.com/channel/UCOgDIiVa8JdSX5T2Zswz9Bw/videos"
                        target="blank"
                      >
                        <Icon name="youtube" className="menu-icon-compressed" />
                      </a>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <a
                        href="https://twitter.com/shityoushouldbe"
                        target="blank"
                      >
                        <Icon name="twitter" className="menu-icon-compressed" />
                      </a>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <a
                        href="https://www.tiktok.com/@sysblt?lang=en"
                        target="blank"
                      >
                        <FontAwesomeIcon
                          className="menu-icon-compressed"
                          icon={faTiktok}
                        />
                      </a>
                    </Dropdown.Item>
                    <Dropdown.Item id="compressed-profile-dropdown">
                      {!isAuthorized && (
                        <Dropdown icon="user">
                          <Dropdown.Menu className="user-icon-dropdown">
                            <Dropdown.Item
                              onClick={() => dispatch(login())}
                              id="login-menu-item"
                            >
                              login
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      )}
                      {isAuthorized && profile && (
                        <Dropdown icon="user" className="active-icon">
                          <Dropdown.Menu className="user-icon-dropdown">
                            <Dropdown.Item id="login-menu-item">
                              <a
                                href={profile.external_urls.spotify}
                                target="blank"
                                style={{ color: "#fd20cb" }}
                              >
                                {profile.display_name}
                              </a>
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => dispatch(logout())}
                              id="login-menu-item"
                            >
                              logout
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      )}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Menu.Item>
            </>
          )}
        </Menu.Menu>
      </StyledMenu>
    </div>
  );
};

export default GlobalHeader;
