const REDIRECT_URI = `${window.location.origin}/callback/`;

const SCOPE = [
  "user-read-private",
  "user-follow-modify",
  "playlist-read-private",
  "playlist-modify-private",
  "playlist-modify-public",
  "user-read-email",
  "user-read-playback-state",
  "user-modify-playback-state",
  "user-read-currently-playing",
  "ugc-image-upload",
  "streaming",
].join("%20");

const LOCAL_STORAGE_KEYS = {
  ACCESS_TOKEN: "sysbltAccessToken",
  REFRESH_TOKEN: "sysbltRefreshToken",
  TOKEN_EXPIRY: "sysbltTokenExpiry",
  PROFILE: "sysbltProfile",
  SCOPE: "sysbltSpotifyScope",
};

const CLIENT_ID = "7e8c3ad51bc24b84aede87947819e061";

const LOGIN_URL = `https://accounts.spotify.com/authorize?client_id=${CLIENT_ID}&response_type=code&redirect_uri=${REDIRECT_URI}&scope=${SCOPE}`;
const API_BASE_LOGIN =
  "https://231q53v3g2.execute-api.us-east-1.amazonaws.com/SYSBFunction";
const API_BASE_REFRESH =
  "https://x3w69aqpa4.execute-api.us-east-1.amazonaws.com/SYSBFuncRefresh";

class SpotifyAuthentication {
  constructor() {
    this.accessToken = localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
    this.refreshToken = localStorage.getItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN);
    this.tokenExpiry = localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN_EXPIRY);
    this.profile = localStorage.getItem(LOCAL_STORAGE_KEYS.PROFILE);
  }

  login = () => {
    return new Promise((resolve, reject) => {
      const popup = window.open(
        LOGIN_URL,
        "_blank",
        "width=700,height=500,toolbar=0,menubar=0,location=0,status=1,scrollbars=1,resizable=1,left=0,top=0"
      );

      const listener = setInterval(() => {
        popup.postMessage("login", window.location);
      }, 1000);

      window.onmessage = (event) => {
        const message = event.data;
        clearInterval(listener);
        this.exchangeCode(message).then((profile) => {
          resolve(profile);
          window.onmessage = null;
        });
      };
    });
  };

  logout = () => {
    return new Promise((resolve) => {
      localStorage.removeItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
      localStorage.removeItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN);
      localStorage.removeItem(LOCAL_STORAGE_KEYS.TOKEN_EXPIRY);
      localStorage.removeItem(LOCAL_STORAGE_KEYS.PROFILE);
      resolve();
    });
  };

  exchangeCode = (code) => {
    return new Promise((resolve, reject) => {
      fetch(`${API_BASE_LOGIN}?code=${code}&redirect_uri=${REDIRECT_URI}`)
        .then((r) => r.json())
        .then((response) => {
          const { profile } = response;
          this.storeTokenAndProfile(response);
          resolve(profile);
        });
    });
  };

  getToken = () => {
    return new Promise((resolve, reject) => {
      const accessToken = localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
      const refresh_token = localStorage.getItem(
        LOCAL_STORAGE_KEYS.REFRESH_TOKEN
      );
      const expiry = localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN_EXPIRY);

      if (!accessToken || accessToken === "undefined") {
        reject("No token available");
      }

      const timeNow = new Date().getTime();
      const expired = expiry - timeNow < 1000 * 60 * 5;
      if (accessToken && expired) {
        this.refreshAccessToken(refresh_token).then((newAccessToken) => {
          resolve(newAccessToken);
        });
      } else {
        resolve(accessToken);
      }
    });
  };

  tokenNeedsRefreshing = () => {
    const expiry = localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN_EXPIRY);

    const timeNow = new Date().getTime();
    const expired = expiry - timeNow < 1000 * 60 * 5;

    return expired;
  };

  refreshAccessToken = (refreshToken) => {
    return new Promise((resolve) => {
      fetch(`${API_BASE_REFRESH}?refresh_token=${refreshToken}`)
        .then((r) => r.json())
        .then((response) => {
          const { access_token } = response;
          this.storeTokenAndProfile(response);
          resolve(access_token);
        });
    });
  };

  storeTokenAndProfile = ({
    access_token,
    refresh_token,
    expires_in,
    scope,
    profile,
  }) => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, access_token);
    localStorage.setItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN, refresh_token);
    localStorage.setItem(
      LOCAL_STORAGE_KEYS.TOKEN_EXPIRY,
      new Date().getTime() + expires_in * 1000
    );
    scope && localStorage.setItem(LOCAL_STORAGE_KEYS.SCOPE, scope);
    profile &&
      localStorage.setItem(LOCAL_STORAGE_KEYS.PROFILE, JSON.stringify(profile));
  };

  storeProfile = (profile) => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.PROFILE, JSON.stringify(profile));
  };

  isAuthenticated = () => {
    const token = localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
    const scope = localStorage.getItem(LOCAL_STORAGE_KEYS.SCOPE);

    if (!scope || !scope.includes("streaming")) {
      return false;
    }
    if (token === "undefined" || !token) return false;

    return true;
  };

  getProfile = () => {
    const profile = localStorage.getItem(LOCAL_STORAGE_KEYS.PROFILE);
    return profile ? JSON.parse(profile) : {};
  };
}

export default new SpotifyAuthentication();
