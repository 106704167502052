import React from "react";
import { Grid, Divider } from "semantic-ui-react";
import withHomepageContent from "./HOC/withHomepageContent";
import TodaysSong from "./TodaysSong";
import WhatsNew from "./WhatsNew";

const HomePage = () => {
  return (
    <div style={{ paddingTop: "125px" }}>
      <Grid columns="two">
        <Grid.Row>
          <Grid.Column>
            <TodaysSong />
          </Grid.Column>
          <Grid.Column>
            <Grid columns="one">
              <Grid.Row>
                <WhatsNew />
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <iframe
                    title="spotify"
                    src="https://open.spotify.com/embed/playlist/7gBkarvSngkp7J4ne2FhRL"
                    width="80%"
                    height="900"
                    frameBorder="0"
                    allowtransparency="true"
                    allow="encrypted-media"
                  ></iframe>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>
        <Divider hidden />
      </Grid>
    </div>
  );
};

export default withHomepageContent(HomePage);
