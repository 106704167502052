import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  selectSongs,
  setTodaysSong,
} from "../../features/spotifyAuth/homepageSlice";
import { Grid, Loader, Segment } from "semantic-ui-react";
import SongImage from "./SongImage";
import { selectIsAuthorized } from "../../features/spotifyAuth/spotifyAuthSlice";
import { setUriList } from "../../features/spotifyAuth/playerSlice";
import { setPlaylistOffset } from "../../features/spotifyAuth/listenTabSlice";

const SongList = () => {
  const songs = useSelector(selectSongs);
  const dispatch = useDispatch();
  const [songImageList, setSongImageList] = useState(null);
  const [offsetSongIndex, setOffsetSongIndex] = useState(16);
  const [isBottom, setIsBottom] = useState(false);
  const [bottomLoading, setBottomLoading] = useState(false);
  const isAuthorized = useSelector(selectIsAuthorized);

  function handleScroll() {
    const scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    const scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;

    if (scrollTop + window.innerHeight + 50 >= scrollHeight) {
      setIsBottom(true);
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    if (isBottom) {
      setBottomLoading(true);
      setTimeout(() => {
        setBottomLoading(false);
        setOffsetSongIndex(offsetSongIndex + 8);
      }, 1000);
      setIsBottom(false);
    }
  }, [isBottom, offsetSongIndex]);

  useEffect(() => {
    if (songs) {
      let list = [];
      let chunk;
      const songsToRender = songs.slice(5).slice(0, offsetSongIndex);
      while (songsToRender.length > 0) {
        chunk = songsToRender.splice(0, 4);
        list.push(chunk);
      }
      setSongImageList(list);
    }
  }, [songs, offsetSongIndex]);

  const handleClick = (e, song) => {
    dispatch(setTodaysSong(song));
    dispatch(setPlaylistOffset(0));
    dispatch(setUriList([song.uri]));
    setTimeout(() => {}, 1000);
  };

  const handleUnauthorizedClick = (e, song) => {
    dispatch(setTodaysSong(song));
    setTimeout(() => {}, 1000);
  };

  const BottomLoader = () => (
    <Segment
      textAlign="center"
      style={{ height: "272px" }}
      inverted
      placeholder
    >
      <Loader active />
    </Segment>
  );

  if (!songImageList) return <div>loading...</div>;

  if (songImageList) {
    const songImages = songImageList.map((arr, i) => {
      return (
        <Grid.Row key={i}>
          <Grid.Column>
            <SongImage
              handleUnauthorizedClick={handleUnauthorizedClick}
              isAuthorized={isAuthorized}
              song={arr[0]}
              handleClick={handleClick}
              size="medium"
              src={arr[0].image}
            />
          </Grid.Column>
          <Grid.Column>
            <SongImage
              handleUnauthorizedClick={handleUnauthorizedClick}
              isAuthorized={isAuthorized}
              song={arr[1]}
              handleClick={handleClick}
              size="medium"
              src={arr[1].image}
            />
          </Grid.Column>
          <Grid.Column>
            <SongImage
              handleUnauthorizedClick={handleUnauthorizedClick}
              isAuthorized={isAuthorized}
              song={arr[2]}
              handleClick={handleClick}
              size="medium"
              src={arr[2].image}
            />
          </Grid.Column>
          <Grid.Column>
            <SongImage
              handleUnauthorizedClick={handleUnauthorizedClick}
              isAuthorized={isAuthorized}
              song={arr[3]}
              handleClick={handleClick}
              size="medium"
              src={arr[3].image}
            />
          </Grid.Column>
        </Grid.Row>
      );
    });
    return (
      <div>
        <Grid columns="four">{songImages}</Grid>
        {bottomLoading ? <BottomLoader /> : null}
      </div>
    );
  }
};

export default SongList;
