import React from "react";
import { useSelector } from "react-redux";
import { selectTodaysSong } from "../../features/spotifyAuth/homepageSlice";
import { Image, Grid, List, Icon, Button, Container } from "semantic-ui-react";
import styled from "styled-components";

const Date = styled.p`
  margin: 0 !important;
  font-weight: 700;
  font-size: 15px;
  font-family: "SinkinSans-700Bold";
`;

const ArtistName = styled.p`
  margin: 0 !important;
  font-weight: 900;
  font-size: 30px;
  font-family: "SinkinSans-900XBlack";
`;

const SongTitle = styled.p`
  margin: 0 !important;
  font-weight: 800;
  font-size: 25px;
  font-family: "SinkinSans-800Black";
`;

const Producer = styled.p`
  margin: 0 !important;
  font-weight: 800;
  font-size: 15px;
  font-family: "SinkinSans-600SemiBold";
`;

const Writer = styled.p`
  margin: 0 !important;
  font-weight: 600;
  font-size: 15px;
  font-family: "SinkinSans-600SemiBold";
`;

const ListenOn = styled.p`
  padding-right: 20px;
  margin: 0 !important;
  font-weight: bold;
  font-size: large;
  font-family: "SinkinSans-600SemiBold";
`;

const DiscoverTodaysSong = () => {
  const todaysSong = useSelector(selectTodaysSong);

  return (
    <Container
      style={{
        position: "fixed",
        backgroundColor: "black",
        zIndex: "99",
        marginTop: "-25px",
        marginBottom: "25px",
      }}
    >
      <Grid padded style={{ borderBottom: "1px solid #FD20CB" }}>
        <Grid.Row>
          <Grid.Column width={3}>
            <Image src={todaysSong.image} size="large"></Image>
          </Grid.Column>
          <Grid.Column width={9}>
            <div style={{ textAlign: "left" }}>
              <Date>{todaysSong.date}</Date>
              <ArtistName>{todaysSong.artist}</ArtistName>
              <SongTitle>{todaysSong.song}</SongTitle>
              <List size="large">
                <List.Item>
                  <List.Icon name="point" />
                  <List.Content>
                    <List.Header
                      style={{
                        font: "SinkinSans-600SemiBold",
                        fontSize: "20px",
                      }}
                    >
                      {todaysSong.location}
                    </List.Header>
                  </List.Content>
                </List.Item>
              </List>
              <Producer>PRODUCED BY: {todaysSong.producer}</Producer>
              <Writer>WRITTEN BY: {todaysSong.writer}</Writer>
            </div>
          </Grid.Column>
          <Grid.Column width={4}>
            <div style={{ textAlign: "right" }}>
              <ListenOn>LISTEN ON</ListenOn>
              <br></br>
              <Button.Group vertical>
                <Button
                  className="spot-app-buttons"
                  as="a"
                  href={todaysSong.spotify}
                  target="blank"
                  inverted
                >
                  <Icon name="spotify" />
                  Spotify
                </Button>
                <br></br>
                <Button
                  className="spot-app-buttons"
                  as="a"
                  href={todaysSong.applemusic}
                  target="blank"
                  inverted
                >
                  <Icon name="apple" />
                  Apple Music
                </Button>
                <br></br>
                <List
                  size="huge"
                  horizontal
                  verticalAlign="bottom"
                  style={{ paddingRight: "15px" }}
                >
                  <List.Item>
                    <List.Icon name="instagram" />
                  </List.Item>
                  <List.Item>
                    <List.Icon name="twitter" />
                  </List.Item>
                  <List.Item>
                    <List.Icon name="youtube" />
                  </List.Item>
                </List>
              </Button.Group>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
};

export default DiscoverTodaysSong;
