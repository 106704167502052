import React from "react";
import { Image, Container } from "semantic-ui-react";
import gif from "../../images/sysb2OPTOMISED.gif";
import { Link } from "react-router-dom";
import styled from "styled-components";

const ComingSoon = styled.span`
  font-size: 20px;
  font-weight: bold;
  width: 75%;
  font-family: "SinkinSans-600SemiBold";
  text-align: "center";
`;

const Shop = () => {
  return (
    <Container>
      <Link to="/home">
        <Image
          style={{ marginTop: "100px" }}
          centered
          size="massive"
          src={gif}
        ></Image>
      </Link>
      <ComingSoon>Coming soon...</ComingSoon>
    </Container>
  );
};

export default Shop;
