import React, { useEffect } from "react";
import {
  selectPlaylists,
  getPlaylists,
  getTodaysSong,
  getSongs,
  getWhatsNew,
} from "../../../features/spotifyAuth/homepageSlice";
import { useDispatch, useSelector } from "react-redux";

const withHomepageContent = (WrappedComponent) => (props) => {
  const playlists = useSelector(selectPlaylists);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPlaylists());
    dispatch(getTodaysSong());
    dispatch(getSongs());
    dispatch(getWhatsNew());
  }, [dispatch]);

  if (playlists) return <WrappedComponent playlists={playlists} {...props} />;

  if (!playlists) return <div>loading...</div>;
};

export default withHomepageContent;
