import React from "react";
import { Grid } from "semantic-ui-react";

const WatchTabColumn = ({ video }) => {
  return (
    <Grid.Column style={{ textAlign: "left" }}>
      <div className="video-container">
        <iframe
          className="video"
          title={video.title}
          src={video.videolink}
          allowFullScreen
        ></iframe>
      </div>
      <span style={{ marginTop: "2px" }}>{video.title}</span>
    </Grid.Column>
  );
};

export default WatchTabColumn;
