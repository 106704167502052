import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectCurrentSong } from "../spotifyAuth/listenTabSlice";
import { selectIsAuthorized } from "../spotifyAuth/spotifyAuthSlice";
import TrackListItem from "./TrackListItem";
import { Menu, Button, Icon } from "semantic-ui-react";
import Spotify from "spotify-web-api-js";

const TrackList = ({ playlist }) => {
  const currentSong = useSelector(selectCurrentSong);
  const isAuthorized = useSelector(selectIsAuthorized);
  const [tracks, setTracks] = useState([]);
  const [offset, setOffset] = useState(0);
  const [playlistLength, setplaylistLength] = useState(0);

  useEffect(() => {
    if (isAuthorized) {
      const spotify = new Spotify();
      const token = localStorage.getItem("sysbltAccessToken");
      spotify.setAccessToken(token);
      spotify.getPlaylist(playlist.uri.split(":")[2]).then((res) => {
        setplaylistLength(res.tracks.items.length);
        if (offset >= res.tracks.items.length) {
          setOffset(0);
        }
      });
      spotify
        .getPlaylistTracks(playlist.uri.split(":")[2], {
          limit: 10,
          offset: offset,
        })
        .then((res) => {
          setTracks(res.items);
        });
    }
  }, [isAuthorized, playlist, offset]);

  const listItems = tracks.map((track, i) => {
    if (!currentSong) {
      return (
        <TrackListItem
          playlist={playlist}
          track={track}
          offsetIndex={i}
          offset={offset}
          key={i}
          isActive={false}
        />
      );
    }
    if (currentSong) {
      return (
        <TrackListItem
          playlist={playlist}
          track={track}
          offsetIndex={i}
          offset={offset}
          key={i}
          isActive={track.track.name === currentSong.name}
        />
      );
    }
    return null;
  });

  const increaseOffset = () => {
    setOffset(offset + 10);
  };

  const decreaseOffset = () => {
    setOffset(offset - 10);
  };

  const offsetButtons = () => {
    if (offset === 0 && playlistLength !== 10) {
      return (
        <Button icon onClick={increaseOffset}>
          <Icon name="right arrow" />
        </Button>
      );
    }
    if (playlistLength === 10) {
      return null;
    }
    if (offset >= playlistLength) {
      return (
        <Button icon onClick={decreaseOffset}>
          <Icon name="left arrow" />
        </Button>
      );
    }

    return (
      <div>
        <Button icon onClick={decreaseOffset}>
          <Icon name="left arrow" />
        </Button>
        <Button icon onClick={increaseOffset}>
          <Icon name="right arrow" />
        </Button>
      </div>
    );
  };

  if (isAuthorized) {
    return (
      <div style={{ margin: "0 10%" }}>
        <Menu vertical fluid>
          {listItems}
        </Menu>
        {offsetButtons()}
      </div>
    );
  }

  if (!isAuthorized)
    return (
      <div>
        <iframe
          title="spotify"
          src={`https://open.spotify.com/embed/playlist/${
            playlist.uri.split(":")[2]
          }`}
          width="80%"
          height="450"
          frameBorder="0"
          allowtransparency="true"
          allow="encrypted-media"
        ></iframe>
      </div>
    );
};

export default TrackList;
