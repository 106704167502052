import React from "react";
import { Container, Grid, Menu, Icon, Image, Divider } from "semantic-ui-react";
import logo from "../../logo.png";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTiktok } from "@fortawesome/free-brands-svg-icons";

const Title = styled.span`
  font-size: 28px;
  font-family: "SinkinSans-900XBlack";
`;

const Section = styled.span`
  font-size: 20px;
  font-family: "SinkinSans-800Black";
`;

const Email = styled.a`
  font-size: 15px;
  color: #ff00cd;
  font-family: "SinkinSans-700Bold";
  &:hover {
    color: #b23499;
  }
`;

const StyledMenu = styled(Menu)`
  background-color: black!important;
  height: 75px;
  width: 50% !important;
  margin: 0 auto !important;
  font-family: 'SinkinSans-700Bold';
}
`;

const Contact = () => {
  return (
    <Container style={{ paddingTop: "100px" }}>
      <Grid>
        <Grid.Row columns={1}>
          <Grid.Column style={{ textAlign: "center" }}>
            <Title>Contact Information</Title>
            <Divider hidden />
            <Divider hidden />
            <Section>General Inquiries</Section>
            <br></br>
            <br></br>
            <Email href="mailto: ShitYouShouldBeListeningTo@gmail.com">
              ShitYouShouldBeListeningTo@gmail.com
            </Email>
            <Divider hidden />
            <Section>What’s the shit we should be listening to?</Section>
            <br></br>
            <br></br>
            <Email href="mailto: Submissions.sysb@gmail.com ">
              submissions.sysb@gmail.com
            </Email>
            <Divider hidden />

            <Image src={logo} size="medium" centered />
            <Divider hidden />
            <StyledMenu>
              <Container
                textAlign="center"
                style={{ width: "50%", margin: "0 auto" }}
              >
                <Menu.Item name="instagram">
                  <a
                    href="https://www.instagram.com/shityoushouldbelisteningto/?hl=en"
                    target="blank"
                  >
                    <Icon name="instagram" className="menu-icon-contact" />
                  </a>
                </Menu.Item>
                <Menu.Item name="spotify">
                  <a
                    href="https://open.spotify.com/user/mj4qe0lyr7yqzm0zwqn5lc7yt?si=CB3hGTztTaSzionpVDbpCw"
                    target="blank"
                  >
                    <Icon name="spotify" className="menu-icon-contact" />
                  </a>
                </Menu.Item>
                <Menu.Item name="apple">
                  <a
                    href="https://music.apple.com/profile/shityoushouldbelisteningto"
                    target="blank"
                  >
                    <Icon name="apple" className="menu-icon-contact" />
                  </a>
                </Menu.Item>
                <Menu.Item name="youtube">
                  <a
                    href="https://www.youtube.com/channel/UCOgDIiVa8JdSX5T2Zswz9Bw/videos"
                    target="blank"
                  >
                    <Icon name="youtube" className="menu-icon-contact" />
                  </a>
                </Menu.Item>
                <Menu.Item name="twitter">
                  <a href="https://twitter.com/shityoushouldbe" target="blank">
                    <Icon name="twitter" className="menu-icon-contact" />
                  </a>
                </Menu.Item>
                <Menu.Item name="tiktok">
                  <a
                    href="https://www.tiktok.com/@sysblt?lang=en"
                    target="blank"
                  >
                    <FontAwesomeIcon
                      className="menu-icon-contact"
                      icon={faTiktok}
                    />
                  </a>
                </Menu.Item>
              </Container>
            </StyledMenu>
            <Divider hidden />
            <span>&copy; 2021 SYSB LLC</span>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
};

export default Contact;
