import { createSlice } from "@reduxjs/toolkit";
import SpotifyAuthentication from "../../services/SpotifyAuthentication";

export const spotifyAuthSlice = createSlice({
  name: "spotifyAuth",
  initialState: {
    isAuthorized: SpotifyAuthentication.isAuthenticated(),
    profile: SpotifyAuthentication.getProfile(),
  },
  reducers: {
    authorize: (state, action) => {
      state.isAuthorized = true;
      state.profile = action.payload;
    },
    setProfile: (state, action) => {
      return {
        ...state,
        profile: action.payload,
      };
    },
    deauthorize: (state) => {
      state.isAuthorized = false;
      state.profile = null;
    },
  },
});

export const { authorize, setProfile, deauthorize } = spotifyAuthSlice.actions;

export const login = () => (dispatch) => {
  SpotifyAuthentication.login().then((profile) => {
    dispatch(authorize(profile));
  });
};

export const logout = () => (dispatch) => {
  SpotifyAuthentication.logout().then((res) => {
    dispatch(deauthorize());
  });
};

export const selectIsAuthorized = (state) => state.spotifyAuth.isAuthorized;
export const selectProfile = (state) => state.spotifyAuth.profile;

export default spotifyAuthSlice.reducer;
