import { createSlice } from "@reduxjs/toolkit";
import ListenSheet from "../../services/ListenSheet";

export const watchTabSlice = createSlice({
  name: "watchTab",
  initialState: {
    currentVideos: null,
  },
  reducers: {
    setVideos: (state, action) => {
      state.currentVideos = action.payload;
    },
  },
});

export const { setVideos } = watchTabSlice.actions;

export const getLatestVideos = () => (dispatch) => {
  ListenSheet.getLatestVideos().then((videos) => {
    dispatch(setVideos(videos));
  });
};

export const selectCurrentVideos = (state) => state.watchTab.currentVideos;

export default watchTabSlice.reducer;
