import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  selectCurrentVideos,
  getLatestVideos,
} from "../../features/spotifyAuth/watchTabSlice";
import { Grid, Container, Divider, Menu } from "semantic-ui-react";
import WatchTabColumn from "./WatchTabColumn";
import styled from "styled-components";

const Date = styled.p`
  font-weight: bold;
  margin-bottom: 3px;
  font-size: 15pxem;
  font-family: "SinkinSans-700Bold";
`;

const Caption = styled.p`
  font-size: 20px;
  font-weight: bold;
  width: 75%;
  font-family: "SinkinSans-600SemiBold";
`;

const Top = styled.p`
  font-weight: bold;
  font-size: 28px;
  margin: 0;
  font-family: "SinkinSans-900XBlack";
`;

const WatchTab = () => {
  const currentVideos = useSelector(selectCurrentVideos);
  const dispatch = useDispatch();
  const [stsSeason, setStsSeason] = useState("2");
  const [ssSeason, setSsSeason] = useState("1");

  useEffect(() => {
    dispatch(getLatestVideos());
  }, [dispatch]);

  const stsVideoRows = () => {
    return currentVideos
      .filter(
        (video) => video.season === stsSeason && video.name === "SHOOT THE SH*T"
      )
      .map((video) => {
        return <WatchTabColumn video={video} key={video.id} />;
      });
  };

  const ssVideoRows = () => {
    return currentVideos
      .filter(
        (video) => video.season === ssSeason && video.name === "ScreenSharing"
      )
      .map((video) => {
        return <WatchTabColumn video={video} key={video.id} />;
      });
  };

  const handleStsSeasonClick = (e, { season }) => {
    setStsSeason(season);
  };

  const handleSsSeasonClick = (e, { season }) => {
    setSsSeason(season);
  };

  if (currentVideos) {
    const mainVideo = currentVideos[currentVideos.length - 1];
    return (
      <div style={{ paddingTop: "100px" }}>
        <Container>
          <Grid>
            <Grid.Row>
              <Grid.Column width={8} style={{ textAlign: "left" }}>
                <div className="video-container">
                  <iframe
                    className="video"
                    title={mainVideo.title}
                    src={mainVideo.videolink}
                    allowFullScreen
                  ></iframe>
                </div>
              </Grid.Column>
              <Grid.Column
                width={8}
                style={{ textAlign: "left" }}
                verticalAlign="middle"
              >
                <Top>
                  {mainVideo.name.toUpperCase()} {" | "}
                  {mainVideo.title.toUpperCase()}
                </Top>
                <Date>{mainVideo.date.toUpperCase()}</Date>
                <Divider hidden />
                <Caption>{mainVideo.caption}</Caption>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Divider hidden />
          <Divider hidden />
          <Divider hidden />
          <Grid columns={3}>
            <Grid.Row style={{ padding: "0px" }}>
              <Grid.Column style={{ textAlign: "left" }}>
                <Top>SHOOT THE SHIT</Top>
                <Menu className="sts-season-menu">
                  <Menu.Item
                    className="sts-season-button"
                    fitted
                    season="1"
                    active={stsSeason === "1"}
                    onClick={handleStsSeasonClick}
                  >
                    SEASON 1
                  </Menu.Item>
                  <Menu.Item
                    className="sts-season-button"
                    fitted
                    season="2"
                    active={stsSeason === "2"}
                    onClick={handleStsSeasonClick}
                  >
                    SEASON 2
                  </Menu.Item>
                </Menu>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row style={{ padding: "0px" }}>{stsVideoRows()}</Grid.Row>
            <Grid.Row>
              <Grid.Column style={{ textAlign: "left" }}>
                <Top>SCREEN SHARING</Top>
                <Menu className="sts-season-menu">
                  <Menu.Item
                    className="sts-season-button"
                    fitted
                    season="1"
                    active={ssSeason === "1"}
                    onClick={handleSsSeasonClick}
                  >
                    SEASON 1
                  </Menu.Item>
                </Menu>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row style={{ marginBottom: "100px", padding: "0px" }}>
              {ssVideoRows()}
            </Grid.Row>
          </Grid>
        </Container>
      </div>
    );
  }

  return <div>loading...</div>;
};

export default WatchTab;
