import React from "react";
import { Divider } from "semantic-ui-react";
import withDiscoverContent from "./HOC/withDiscoverContent";
import SongList from "./SongList";
import DiscoverTodaysSong from "./DiscoverTodaysSong";

const Discover = () => (
  <div style={{ paddingTop: "100px" }}>
    <DiscoverTodaysSong />
    <Divider hidden style={{ height: "210px" }} />
    <SongList />
    <Divider hidden />
  </div>
);

export default withDiscoverContent(Discover);
