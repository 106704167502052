import React, { useEffect } from "react";

export function Callback() {
  useEffect(() => {
    const hash = window.location.href;
    let hasCode = hash.includes("code");

    if (hasCode) {
      window.addEventListener("message", (event) => {
        var message = event.data;
        if (message === "login") {
          const splitHash = hash.split(/=|&/g);
          const code = splitHash[1];
          event.source.postMessage(code, event.origin);
          window.close();
        }
      });
    }
  });

  return <div>callback </div>;
}
