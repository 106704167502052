import React from "react";
import "./App.css";
import ListenTab from "./features/player/ListenTab";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Callback } from "./app/components/Callback";
import GlobalHeader from "./app/components/GlobalHeader";
import HomePage from "./app/components/HomePage";
import { Container } from "semantic-ui-react";
import GlobalFooter from "./app/components/GlobalFooter";
import Discover from "./app/components/Discover";
import WatchTab from "./app/components/WatchTab";
import Contact from "./app/components/Contact";
import LandingPage from "./app/components/LandingPage";
import Shop from "./app/components/Shop";

function App() {
  const AppRoutes = () => {
    return (
      <div>
        <GlobalHeader />
        <Container>
          <Switch>
            <Route path="/home" component={HomePage} />
            <Route path="/listen" component={ListenTab} />
            <Route path="/watch" component={WatchTab} />
            <Route path="/callback" component={Callback} />
            <Route path="/discover" component={Discover} />
            <Route path="/contact" component={Contact} />
            <Route path="/shop" component={Shop} />
            <Route component={LandingPage} />
          </Switch>
        </Container>
        <GlobalFooter />
      </div>
    );
  };

  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/" component={LandingPage} />
          <Route component={AppRoutes} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
