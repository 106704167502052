import React from "react";
import { Image, Container } from "semantic-ui-react";
import gif from "../../images/sysb2OPTOMISED.gif";
import { Link } from "react-router-dom";

const LandingPage = () => {
  return (
    <Container>
      <Link to="/home">
        <Image
          style={{ marginTop: "100px" }}
          centered
          size="massive"
          src={gif}
        ></Image>
      </Link>
    </Container>
  );
};

export default LandingPage;
