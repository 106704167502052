import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  selectCurrentPlaylist,
  getPlaylists,
  selectPlaylists,
} from "../spotifyAuth/listenTabSlice";
import {
  Grid,
  Image,
  Button,
  Icon,
  Divider,
  Menu,
  Segment,
} from "semantic-ui-react";
import TrackList from "./TrackList";
import { setUriList } from "../../features/spotifyAuth/playerSlice";
import Spotify from "spotify-web-api-js";
import {
  login,
  selectIsAuthorized,
  selectProfile,
} from "../spotifyAuth/spotifyAuthSlice";

const ListenTab = () => {
  const currentPlaylist = useSelector(selectCurrentPlaylist);
  const dispatch = useDispatch();
  const [activePlaylist, setActivePlaylist] = useState(null);
  const playlists = useSelector(selectPlaylists);
  const isAuthorized = useSelector(selectIsAuthorized);
  const profile = useSelector(selectProfile);
  const [userPlaylists, setUserPlaylists] = useState([]);

  useEffect(() => {
    dispatch(getPlaylists());
  }, [dispatch]);

  useEffect(() => {
    if (playlists) {
      setActivePlaylist(playlists[0]);
    }
  }, [playlists]);

  useEffect(() => {
    if (isAuthorized && currentPlaylist) {
      const spotify = new Spotify();
      const token = localStorage.getItem("sysbltAccessToken");
      spotify.setAccessToken(token);
      let uris = [];
      spotify
        .getPlaylistTracks(currentPlaylist.uri.split(":")[2])
        .then((res) => {
          res.items.map((track) => {
            uris.push(track.track.uri);
            return null;
          });
          dispatch(setUriList(uris));
        });
      spotify
        .getUserPlaylists(profile.display_name, { limit: 50, offset: 0 })
        .then(
          function(data) {
            let pl = [];
            data.items.map((playlist) => {
              return pl.push(playlist.id);
            });
            setUserPlaylists(pl);
          },
          function(err) {
            console.error(err);
          }
        );
    }
  }, [isAuthorized, currentPlaylist, dispatch, profile]);

  const handleMenuClick = (e, { index }) => {
    let playlist = playlists.filter((playlist, i) => i === index)[0];
    setActivePlaylist(playlist);
  };

  const handleLoginClick = () => {
    dispatch(login());
  };

  const handleFollowPlaylist = () => {
    const spotify = new Spotify();
    const token = localStorage.getItem("sysbltAccessToken");
    spotify.setAccessToken(token);
    spotify.followPlaylist(activePlaylist.id);
    setUserPlaylists((prevValue) => [...prevValue, activePlaylist.id]);
  };

  if (activePlaylist) {
    return (
      <div style={{ paddingTop: "100px", paddingBottom: "100px" }}>
        <Grid centered columns="one">
          <Grid.Row>
            <Segment inverted style={{ width: "75%" }}>
              <Menu
                inverted
                pointing
                secondary
                widths={3}
                style={{ fontSize: "1.5em", fontFamily: "Mitr-Regular" }}
              >
                <Menu.Item
                  onClick={handleMenuClick}
                  index={0}
                  name={playlists[0].title.toUpperCase()}
                  active={activePlaylist === playlists[0]}
                />
                <Menu.Item
                  onClick={handleMenuClick}
                  index={1}
                  name={playlists[1].title.toUpperCase()}
                  active={activePlaylist === playlists[1]}
                />
                <Menu.Item
                  onClick={handleMenuClick}
                  index={2}
                  name={playlists[2].title.toUpperCase()}
                  active={activePlaylist === playlists[2]}
                />
              </Menu>
            </Segment>
          </Grid.Row>
        </Grid>
        <Grid
          columns="two"
          style={{
            padding: "40px",
          }}
        >
          <Grid.Row>
            <Grid.Column>
              <Image src={activePlaylist.image} size="large" centered />
              <Divider hidden />
              {isAuthorized && !userPlaylists.includes(activePlaylist.id) && (
                <Button
                  style={{
                    backgroundColor: "#DEF800",
                    color: "black",
                    padding: "2px",
                  }}
                  onClick={handleFollowPlaylist}
                >
                  <Icon name="spotify" />
                  Follow {activePlaylist.title}
                </Button>
              )}
              {isAuthorized && userPlaylists.includes(activePlaylist.id) && (
                <span
                  style={{
                    backgroundColor: "#DEF800",
                    color: "black",
                    padding: "2px",
                  }}
                >
                  <Icon name="spotify" />
                  Following {activePlaylist.title}
                </span>
              )}
              {!isAuthorized && (
                <Button
                  style={{
                    backgroundColor: "#DEF800",
                    color: "black",
                    padding: "2px",
                  }}
                  onClick={handleLoginClick}
                >
                  <Icon name="spotify" />
                  Connect Spotify Premium
                </Button>
              )}
            </Grid.Column>
            <Grid.Column>
              <TrackList playlist={activePlaylist} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }

  return <div>loading...</div>;
};

export default ListenTab;
