import { configureStore } from "@reduxjs/toolkit";
import spotifyAuthReducer from "../features/spotifyAuth/spotifyAuthSlice";
import listenTabReducer from "../features/spotifyAuth/listenTabSlice";
import homepageReducer from "../features/spotifyAuth/homepageSlice";
import playerReducer from "../features/spotifyAuth/playerSlice";
import watchTabReducer from "../features/spotifyAuth/watchTabSlice";

export default configureStore({
  reducer: {
    spotifyAuth: spotifyAuthReducer,
    listenTab: listenTabReducer,
    homepage: homepageReducer,
    player: playerReducer,
    watchTab: watchTabReducer,
  },
});
