import { createSlice } from "@reduxjs/toolkit";

export const playerSlice = createSlice({
  name: "player",
  initialState: {
    uriList: ["spotify:playlist:7gBkarvSngkp7J4ne2FhRL"],
  },
  reducers: {
    setUriList: (state, action) => {
      state.uriList = action.payload;
    },
  },
});

export const { setUriList } = playerSlice.actions;

export const selectUriList = (state) => state.player.uriList;

export default playerSlice.reducer;
