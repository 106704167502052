import React from "react";
import { Menu, Icon, Button, Header } from "semantic-ui-react";
import styled from "styled-components";
import {
  login,
  selectIsAuthorized,
} from "../../features/spotifyAuth/spotifyAuthSlice";
import { selectUriList } from "../../features/spotifyAuth/playerSlice";
import { useSelector, useDispatch } from "react-redux";
import Player from "../../features/player/Player";

const StyledMenu = styled(Menu)`
  background-color: black !important;
  height: 75px;
  font-family: "Mitr-SemiBold";
`;

const GlobalFooter = () => {
  const dispatch = useDispatch();
  const authorized = useSelector(selectIsAuthorized);
  const uriList = useSelector(selectUriList);

  if (authorized && uriList) {
    return <Player />;
  }

  if (!authorized) {
    return (
      <StyledMenu fixed="bottom">
        <Menu.Item>
          <Header as="h5" color="grey">
            Sign in to listen to music
          </Header>
        </Menu.Item>
        <Menu.Menu position="right">
          <Menu.Item>
            <Button
              onClick={() => dispatch(login())}
              inverted
              icon
              labelPosition="left"
            >
              <Icon name="spotify" />
              Connect Spotify
            </Button>
          </Menu.Item>
        </Menu.Menu>
      </StyledMenu>
    );
  }
};

export default GlobalFooter;
