import React, { useEffect, useState, useMemo } from "react";
import Spotify from "spotify-web-api-js";
import { useSelector } from "react-redux";
import SpotifyAuthentication from "../../services/SpotifyAuthentication";
import { selectIsAuthorized } from "../spotifyAuth/spotifyAuthSlice";

const withToken = (WrappedComponent) => (props) => {
  const [token, setToken] = useState(null);
  const isAuthorized = useSelector(selectIsAuthorized);

  const spotify = useMemo(() => {
    return new Spotify();
  }, []);

  useEffect(() => {
    const validateToken = () => {
      return new Promise((resolve) => {
        SpotifyAuthentication.getToken().then((token) => {
          spotify.setAccessToken(token);
          resolve(token);
        });
      });
    };
    if (isAuthorized) {
      validateToken().then((token) => {
        setToken(token);
      });
    }
  }, [isAuthorized, spotify]);

  if (token)
    return <WrappedComponent token={token} spotify={spotify} {...props} />;

  if (!token) return null;
};

export default withToken;
