import React, { useState, useEffect } from "react";
import {
  Image,
  Header,
  Grid,
  Divider,
  List,
  Icon,
  Loader,
  Segment,
} from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";
import {
  selectTodaysSong,
  selectSongs,
  setTodaysSong,
} from "../../features/spotifyAuth/homepageSlice";
import styled from "styled-components";
import SongImage from "./SongImage";
import { setUriList } from "../../features/spotifyAuth/playerSlice";
import { selectIsAuthorized } from "../../features/spotifyAuth/spotifyAuthSlice";
import LoaderSegment from "./LoaderSegment";

const ArtistName = styled.p`
  margin: 0 !important;
  font-weight: bold;
  font-size: larger;
  font-family: "Mitr-SemiBold";
`;

const ArtistLocation = styled.p`
  margin: 0 !important;
  font-weight: bold;
  font-size: smaller;
  font-family: "Mitr-SemiBold";
`;

const SongTitle = styled.p`
  margin: 0 !important;
  font-weight: bold;
  font-size: larger;
  font-family: "Mitr-SemiBold";
`;

const Producer = styled.p`
  margin: 0 !important;
  font-weight: bold;
  font-size: smaller;
  font-family: "Mitr-SemiBold";
`;

const Writer = styled.p`
  margin: 0 !important;
  font-weight: bold;
  font-size: smaller;
  font-family: "Mitr-SemiBold";
`;

const Listen = styled.p`
  margin: 0 !important;
  font-weight: bold;
  font-size: large;
  font-family: "Mitr-SemiBold";
`;

const TodaysSong = () => {
  const todaysSong = useSelector(selectTodaysSong);
  const songs = useSelector(selectSongs);
  const [todaysSongLoading, setTodaysSongLoading] = useState(false);
  const [localSongsState, setLocalSongsState] = useState(null);
  const dispatch = useDispatch();
  const isAuthorized = useSelector(selectIsAuthorized);

  useEffect(() => {
    if (songs && todaysSong) {
      let songList = songs
        .filter((song) => song.song !== todaysSong.song)
        .slice(0, 6);
      setLocalSongsState(songList);
    }
  }, [todaysSong, songs]);

  if (!todaysSong || !localSongsState)
    return (
      <LoaderSegment style={{ marginTop: "200px", backgroundColor: "black" }} />
    );

  const handleClick = (e, song) => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setTodaysSongLoading(true);
    dispatch(setTodaysSong(song));
    dispatch(setUriList([song.uri]));
    setTimeout(() => {
      setTodaysSongLoading(false);
    }, 1000);
  };

  const handleUnauthorizedClick = (e, song) => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setTodaysSongLoading(true);
    dispatch(setTodaysSong(song));
    setTimeout(() => {
      setTodaysSongLoading(false);
    }, 1000);
  };

  function isOdd(num) {
    return num % 2;
  }

  if (localSongsState && todaysSong) {
    const songRows = localSongsState.map((song, i) => {
      if (isOdd(i)) {
        return (
          <Grid.Row key={i}>
            <Grid.Column>
              <SongImage
                handleUnauthorizedClick={handleUnauthorizedClick}
                isAuthorized={isAuthorized}
                handleClick={handleClick}
                song={localSongsState[i - 1]}
                size="medium"
                src={localSongsState[i - 1].image}
              ></SongImage>
            </Grid.Column>
            <Grid.Column>
              <SongImage
                handleUnauthorizedClick={handleUnauthorizedClick}
                isAuthorized={isAuthorized}
                handleClick={handleClick}
                song={localSongsState[i]}
                size="medium"
                src={localSongsState[i].image}
              ></SongImage>
            </Grid.Column>
          </Grid.Row>
        );
      } else {
        return null;
      }
    });

    return (
      <div>
        <Grid textAlign="left" columns="two">
          <Header floated="left">TODAY'S SONG</Header>
          {!todaysSongLoading ? (
            <Grid.Row>
              <Grid.Column>
                <Image src={todaysSong.image} size="medium" />
              </Grid.Column>
              <Grid.Column>
                <ArtistName>{todaysSong.artist.toUpperCase()}</ArtistName>
                <ArtistLocation>
                  {todaysSong.location.toUpperCase()}
                </ArtistLocation>
                <br></br>
                <SongTitle>{todaysSong.song.toUpperCase()}</SongTitle>
                <br></br>
                <Producer>
                  PRODUCED BY: {todaysSong.producer.toUpperCase()}
                </Producer>
                <Writer>WRITTEN BY: {todaysSong.writer.toUpperCase()}</Writer>
                <br></br>
                <Listen>LISTEN ON</Listen>
                <Divider hidden />
                <List horizontal relaxed>
                  <List.Item as="a" href={todaysSong.spotify} target="blank">
                    <Icon size="big" name="spotify"></Icon>
                  </List.Item>
                  <List.Item as="a" href={todaysSong.applemusic} target="blank">
                    <Icon size="big" name="apple"></Icon>
                  </List.Item>
                </List>
              </Grid.Column>
            </Grid.Row>
          ) : (
            <Grid.Row>
              <Grid centered columns="one">
                <Grid.Column>
                  <Segment style={{ height: "272px" }} inverted placeholder>
                    <Loader active />
                  </Segment>
                </Grid.Column>
              </Grid>
            </Grid.Row>
          )}
          {songRows}
          <Divider hidden />
        </Grid>
      </div>
    );
  }
};

export default TodaysSong;
