import React, { useEffect } from "react";
import {
  selectSongs,
  selectTodaysSong,
  getTodaysSong,
  getSongs,
} from "../../../features/spotifyAuth/homepageSlice";
import { useDispatch, useSelector } from "react-redux";

const withDiscoverContent = (WrappedComponent) => (props) => {
  const songs = useSelector(selectSongs);
  const todaysSong = useSelector(selectTodaysSong);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSongs());
    dispatch(getTodaysSong());
  }, [dispatch]);

  if (songs && todaysSong) return <WrappedComponent {...props} />;

  return <div>loading...</div>;
};

export default withDiscoverContent;
