const LISTEN_SHEET =
  "https://spreadsheets.google.com/feeds/list/19g3jK2bagrOSo7h8HIOifmPmAnR0JBaFXaTWoFOmN74/od6/public/values?alt=json";
const PLAYLISTS_SHEET =
  "https://spreadsheets.google.com/feeds/list/19g3jK2bagrOSo7h8HIOifmPmAnR0JBaFXaTWoFOmN74/od6/public/values?alt=json";
const TODAYS_SONG_SHEET =
  "https://spreadsheets.google.com/feeds/list/19g3jK2bagrOSo7h8HIOifmPmAnR0JBaFXaTWoFOmN74/3/public/values?alt=json";
const WHATS_NEW_SHEET =
  "https://spreadsheets.google.com/feeds/list/19g3jK2bagrOSo7h8HIOifmPmAnR0JBaFXaTWoFOmN74/4/public/values?alt=json";
const LISTEN_TAB_PLAYLISTS_SHEET =
  "https://spreadsheets.google.com/feeds/list/19g3jK2bagrOSo7h8HIOifmPmAnR0JBaFXaTWoFOmN74/2/public/values?alt=json";
const WATCH_TAB_SHEET =
  "https://spreadsheets.google.com/feeds/list/19g3jK2bagrOSo7h8HIOifmPmAnR0JBaFXaTWoFOmN74/5/public/values?alt=json";

class ListenSheet {
  getLatestPlaylist = async () => {
    const response = await fetch(LISTEN_SHEET);
    const playlists = await response.json();
    let playlist = {};
    let obj = playlists.feed.entry[0];
    playlist.title = obj.gsx$title.$t;
    playlist.image = obj.gsx$image.$t;
    playlist.uri = obj.gsx$uri.$t;
    playlist.tracks = [];
    for (const [key, value] of Object.entries(obj)) {
      if (key.includes("track")) {
        playlist.tracks.push(value.$t);
      }
    }
    return playlist;
  };

  getPlaylistsFromSheets = async () => {
    const response = await fetch(PLAYLISTS_SHEET);
    const json = await response.json();
    let playlists = json.feed.entry;
    let playlistsList = [];
    for (const playlist of playlists) {
      let playlistObj = {};
      playlistObj.title = playlist.gsx$title.$t;
      playlistObj.image = playlist.gsx$image.$t;
      playlistObj.uri = playlist.gsx$uri.$t;
      playlistsList.push(playlistObj);
    }
    return playlistsList;
  };

  getTodaysSong = async () => {
    const response = await fetch(TODAYS_SONG_SHEET);
    const json = await response.json();
    let songJson = json.feed.entry[0];
    let song = {};
    song.artist = songJson.gsx$artist.$t;
    song.location = songJson.gsx$location.$t;
    song.song = songJson.gsx$song.$t;
    song.producer = songJson.gsx$producer.$t;
    song.writer = songJson.gsx$writer.$t;
    song.spotify = songJson.gsx$spotify.$t;
    song.applemusic = songJson.gsx$applemusic.$t;
    song.image = songJson.gsx$image.$t;
    song.date = songJson.gsx$date.$t;
    song.uri = songJson.gsx$uri.$t;
    return song;
  };

  getAllSongs = async () => {
    const response = await fetch(TODAYS_SONG_SHEET);
    const json = await response.json();
    let songsJson = json.feed.entry;
    let songs = [];
    for (const song of songsJson) {
      let s = {};
      s.artist = song.gsx$artist.$t;
      s.location = song.gsx$location.$t;
      s.song = song.gsx$song.$t;
      s.producer = song.gsx$producer.$t;
      s.writer = song.gsx$writer.$t;
      s.spotify = song.gsx$spotify.$t;
      s.applemusic = song.gsx$applemusic.$t;
      s.image = song.gsx$image.$t;
      s.date = song.gsx$date.$t;
      s.uri = song.gsx$uri.$t;
      songs.push(s);
    }
    return songs;
  };

  getWhatsNew = async () => {
    const response = await fetch(WHATS_NEW_SHEET);
    const json = await response.json();
    let whatsNewJson = json.feed.entry;
    let whatsNewItems = [];
    for (const item of whatsNewJson) {
      let i = {};
      i.blurb = item.gsx$blurb.$t;
      i.link = item.gsx$link.$t;
      whatsNewItems.push(i);
    }
    return whatsNewItems;
  };

  getListenPlaylists = async () => {
    const response = await fetch(LISTEN_TAB_PLAYLISTS_SHEET);
    const json = await response.json();
    let playlistsJson = json.feed.entry;
    let playlistItems = [];
    for (const item of playlistsJson) {
      let i = {};
      i.title = item.gsx$title.$t;
      i.image = item.gsx$image.$t;
      i.uri = item.gsx$uri.$t;
      i.id = item.gsx$id.$t;
      playlistItems.push(i);
    }
    return playlistItems;
  };

  getLatestVideos = async () => {
    const response = await fetch(WATCH_TAB_SHEET);
    const json = await response.json();
    let videosJson = json.feed.entry;
    let videoItems = [];
    for (const item of videosJson) {
      let i = {};
      i.applemusic = item.gsx$artistapplemusic.$t;
      i.instagram = item.gsx$artistinstagram.$t;
      i.spotify = item.gsx$artistspotify.$t;
      i.twitter = item.gsx$artisttwitter.$t;
      i.caption = item.gsx$caption.$t;
      i.date = item.gsx$date.$t;
      i.title = item.gsx$title.$t;
      i.name = item.gsx$name.$t;
      i.date = item.gsx$date.$t;
      i.season = item.gsx$season.$t;
      i.videolink = item.gsx$videolink.$t;
      videoItems.push(i);
    }
    return videoItems;
  };
}

export default new ListenSheet();
